
.class-checkbox-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    padding-top: 25px;
    margin-left: 30px;
  }
  
  .class-checkbox-user label {
    margin-left: 30px;
  }