.Coluna-padrao-sec{
    border: 1px solid #d6d3d3;
    border-collapse: collapse;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 80px;
    max-width: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }
  
  .Coluna-padrao-sec-desc{
    border: 1px solid #d6d3d3;
    border-collapse: collapse;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 150px;
    max-width: 150px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }
  

  .Coluna-id-sec{
    border: 1px solid #d6d3d3;
    border-collapse: collapse;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 50px;
    max-width: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }