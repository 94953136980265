.container-principal {
  background-color: #fff;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.container-cad-prod1 {
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod2 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod3 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr;
  grid-template-areas: "content1";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod-obs{
  margin-top: 10px;
}

.container-cad-prod4 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod5 {
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod6 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod7 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 3fr 3fr 1.5fr 1.5fr 1.5fr;
  grid-template-areas: "content1 content2 content3 content4 content5 content6";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod8 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod9 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 1fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod10 {
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 0.5fr 0.5fr 2fr 0.5fr 0.5fr 2fr;
  grid-template-areas: "content1 content2 content3 content4 content5 content6 content7";
  grid-gap: 0.5rem;
  margin-bottom: 4px;
}


.container-cad-prod11 {
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 2fr 3fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod12 {
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 2fr 3fr 2fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}


.container-cad-prod13 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 0.5fr 0.8fr 2fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod14 {
  display: grid;
  width: 100%;
  grid-template-columns: 1.2fr 0.9fr 0.7fr 1.8fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod15 {
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 2fr 1fr 2fr 1fr;
  grid-template-areas: "content1 content2 content3 content4 content5";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod16 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 content4 content5";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}


.container-cad-prod60 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 content4 content5";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod17 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod18 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod19 {
  display: grid;
  width: 100%;
  grid-template-columns: 1.5fr 2fr 1.5fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}


.container-cad-prod20 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 content4 content5";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod21 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod22 {
  display: grid;
  width: 100%;
  grid-template-columns: 2.5fr 2.2fr 0.5fr 0.5fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod23 {
  display: grid;
  width: 100%;
  grid-template-columns: 2.5fr 2fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod24 {
  display: grid;
  width: 100%;
  grid-template-columns: 1.5fr 2.5fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod25 {
  display: grid;
  width: 100%;
  grid-template-columns: 1.3fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod26 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;

}

.container-cad-prod27 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod28 {
  display: grid;
  width: 100%;
  grid-template-columns: 0.5fr 3fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod29 {
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 1fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod30{
  display: grid;
  width: 100%;
  grid-template-columns: 0.8fr 1fr 1fr 3fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
}

.container-cad-prod32 {
  display: grid;
  width: 100%;
  grid-template-columns: 0.5fr 3fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod33 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
  margin-top: 10px;
}

.container-cad-prod34 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 3fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
  margin-top: 10px;
}

.container-cad-prod35 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 3fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
  margin-top: 10px;
}

.container-cad-prod36 {
  display: grid;
  width: 100%;
  grid-template-columns: 0.5fr 0.5fr 2fr 0.5fr 0.5fr 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3 content4 content5 content6 content7 content8";
  grid-gap: 0.5rem;
  margin-bottom: 4px;
}

.container-cad-prod37 {
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
  margin-top: 10px;
}

.container-cad-prod38 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 2fr 1fr 1.5fr 1fr;
  grid-template-areas: "content1 content2 content3 content4 content5";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod39 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1.5fr 1.5fr 1.5fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod40 {
  display: grid;
  width: 100%;
  grid-template-columns: 0.4fr 4fr;
  grid-template-areas: "content1 content2 ";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod41 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: "content20 content21";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod42 {
  display: grid;
  width: 100%;
  grid-template-columns: 1.6fr 4.5fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod43 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod44 {
  display: grid;
  width: 100%;
  grid-template-columns: 0.5fr 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod45 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 0.7fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}


.container-cad-prod46 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 conten4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod47{
  display: grid;
  width: 100%;
  grid-template-columns: 0.8fr 3fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
}

.container-cad-prod48{
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 3fr 3fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
}

.container-cad-prod49{
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 0.2fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
}


.container-cad-prod50{
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 0.9fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
}

.container-cad-prod51 {
  display: grid;
  width: 100%;
  grid-template-columns: 0.3fr 0.3fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 4px;
}

.container-cad-prod52 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
}


.container-cad-prod53 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
}

.container-cad-prod54 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod55 {
  display: grid;
  width: 100%;
  grid-template-columns: 1.2fr 1.2fr 1fr 1.1fr 1.1fr;
  grid-template-areas: "content1 content2 content3 content4 content5";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

#content20 {
  grid-area: content20;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

#content21 {
  grid-area: content21;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.input-flex-ativos {
  display: flex;
}

.details-garantia-ativos {
  margin-top: 6px;
  margin-left: 8px;
  margin-right: 8px;
}

.details-textareaBox{
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  color: #12344D;
}


#content1 {
  grid-area: content1;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

#content2 {
  grid-area: content2;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

#content3 {
  grid-area: content3;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

#content4 {
  grid-area: content4;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

#content5 {
  grid-area: content5;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

#content6 {
  grid-area: content6;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

#content7 {
  grid-area: content7;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}
/*---------------------------------DESCRIÇÃO DE CADA BLOCO COM NUMERAÇÃO-------------------------------------------*/
.title-Page .title-text h2 {
  color: #455560;
  font-size: 18px;
  margin-bottom: 13px;
  font-weight: normal;
 margin-top: 3px;
}

.title-Page hr {
  margin-top: 10px;

}

.title-Page {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.title-text {
  display: flex;
  width: 100%;

}

.size-x {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #455560;
  margin-right: 10px;
}

.circle {
  background: #455560;
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.title-Page {
  border-bottom: 1px solid #12344D;
  height: 35px;
  margin-bottom: 10px;
 
}

/*----------------------------------------------------------------------------*/

.modal-titulo {
  font-size: 16px;
  margin-top: 2px;
  margin-left: 10px;
  color: #fff;
}

 .container-cad-dash1 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
}

.container-cad-dash1-act {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}


.container-cad-dash2 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
}


.container-cad-dash3 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr;
  grid-template-areas: "content1";
  grid-gap: 0.5rem;

}

.container-cad-dash4 {
  display: grid;
  width: 100%;
  grid-template-columns: 1.5fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 9px;
}

.container-cad-dash5 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-areas: "content1";
  grid-gap: 0.5rem;
  margin-bottom: 9px;
}

.container-cad-dash6 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 9px;
}


@media only screen and (max-width: 700px) {

  .container-cad-dash1 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
    grid-gap: 0rem;
 
  }
  
    .container-cad-dash2 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2";
    grid-gap: 0.7rem;

  }

  .container-cad-dash3 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2";
    grid-gap: 1rem;
    margin-bottom: 10px;
  }

  .class-checkbox-user {
    padding: 0px;
    margin-top: 10px;
    margin-left: 0px;  
  }

  .container-cad-prod1 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
    grid-gap: 10px;
    margin-bottom: 10px;
  }

  .container-cad-prod2 {
    grid-template-columns: 2fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod4 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod5 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod6 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod7 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4"
      "content5"
      "content6";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod16{
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4"
      "content5";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod8 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2";
      grid-gap: 10px;
      margin-bottom: 10px;
      
  }

  .container-cad-prod9 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod10 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4"
      "content5"
      "content6"
      "content7";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod11 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod12 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod13 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod14 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod15 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4"
      "content5";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod17{
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod18{
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod19{
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
      grid-gap: 10px;
      margin-bottom: 10px;
  }
  .container-cad-prod20 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod21 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2";
  }

  .container-cad-prod22 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
  }

  .container-cad-prod23 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2";
  }

  
  .container-cad-prod24 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2";
  }

  .container-cad-dash1-act {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
	  "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }
.container-cad-prod25{
  grid-template-columns: 1fr;
    grid-template-areas:
      "content1";
      grid-gap: 10px;
      margin-bottom: 10px;
}
.container-cad-prod26 {
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod27{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod28{
  grid-template-columns: 1fr;
  grid-template-areas:
  "content1"
  "content2";
    grid-gap: 10px;
    margin-bottom: 10px;
}

.container-cad-prod29{
  grid-template-columns: 1fr;
  grid-template-areas:
  "content1"
  "content2";
    grid-gap: 10px;
    margin-bottom: 10px;
}

.container-cad-prod30{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3";
    grid-gap: 10px;
    margin-bottom: 10px;
}

.container-cad-prod33 {
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod34 {
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod35 {
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod36{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3"
    "content4"
    "content5"
    "content6"
    "content7"
    "content8";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod37{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod38{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3"
    "content4"
    "content5";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod39{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3"
    "content4";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod40{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod41{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content20"
    "content21";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod42{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod43{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod44{
grid-template-columns: 1fr;
grid-template-areas:
  "content1"
  "content2";
  grid-gap: 10px;
  margin-bottom: 10px;
}
.container-cad-prod45{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3";
    grid-gap: 10px;
    margin-bottom: 10px;
  }
.container-cad-prod46{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod47{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3"
    "content4";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod48{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3";
    grid-gap: 10px;
    margin-bottom: 10px;
}

.container-cad-prod50{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2";
    grid-gap: 10px;
    margin-bottom: 10px;
}

.container-cad-prod52{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3";
    grid-gap: 10px;
    margin-bottom: 10px;
}

.container-cad-prod53{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-dash4{
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2"
    "content3";
    grid-gap: 10px;
    margin-bottom: 10px;
}

.container-cad-dash6 {
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1"
    "content2";
    grid-gap: 10px;
    margin-bottom: 10px;
}
.container-cad-prod54 {
  grid-template-columns: 1fr;
  grid-template-areas:
    "content1";
    grid-gap: 10px;
    margin-bottom: 10px;
}

.container-cad-prod55 {
  grid-template-columns: 1fr;
  grid-template-areas:
  "content1"
  "content2"
  "content3"
  "content4"
  "content5";
    grid-gap: 10px;
    margin-bottom: 10px;
}

}

@media only screen and (max-width: 600px) {

  .title-Page .title-text h2 {  
    font-size: 16px;
  }

  .title-Page {
    border-bottom: 1px solid #12344D;
    height: 40px;
    margin-bottom: 10px; 
  }

}


@media only screen and (max-width: 930px) {

  .container-cad-prod32{
    grid-template-columns: 1fr;
    grid-template-areas:
    "content1"
    "content2";
      grid-gap: 10px;
      margin-bottom: 10px;
  }
}