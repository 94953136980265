.table {
  width: 100%;
  height: 310px;
  background-color: #f5f7fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
padding: 5px;

  .tableTitle {
    font-size: 16px;
    color: #666;
    background-color: #fff;
    font-weight: bold;
  }

  .table-tab {
    border-collapse: separate;
    border-spacing: 0 8px;
  }

  .tableCellHeader {
    font-weight: bold;
    font-size: 14px;
    color: #555;
    border-bottom: 2px solid #e0e0e0;
    padding: 12px;
  }

  .tableCell {
    padding: 5px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #444;
  }

  .tableRow:hover {
    background-color: #ececec;
    cursor: pointer;
  }


  .tableCellID {
    width: 120px;
    color: #00796b;
  }

  .status {
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;

    &.Approved {
      color: #388e3c;
      background-color: rgba(56, 142, 60, 0.15);
    }

    &.Pending {
      color: #f57c00;
      background-color: rgba(245, 124, 0, 0.15);
    }
  }
}
