.btn-busca-movimentacoes2{
    width: 100%;
    background-color: #13293D;
    color:#fff;
    height: 30px;
    margin-top: 23px;
    text-align: center;
    padding-top: 4px;
    border-radius: 5px;
    cursor: pointer;
    
    }

    .lab-movimentacao{
        font-size: 20px;
        }

.ControleIconAlertAtencao{
    color: #f0db22;
    font-size: 100px;
    margin-bottom: 20px;
}

.modal-scrol{
    padding: 20px;
}

@media only screen and (max-width: 420px) {
    .btn-busca-movimentacoes2{
        margin-top: 0px;
    }
}