.card-visualizacao{
    width: 100%;
    margin: 0 auto;
    max-height: 450px;
}





.card-visualizacao .titulo-card-visualizacao{  
    text-align: center;
        background-color: #ececec;
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        color: #000000;
        font-size: 18px;
        box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
        margin-bottom: 20px;  
}

.card-visualizacao .inf-card-visualizacao{
    border-left: 3px solid #13293D;
    padding-left: 20px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
 
}

.card-visualizacao .card-overflow-visu{
    overflow-y:auto;
    max-height: 410px;

}


.card-visualizacao .card-overflow-visu::-webkit-scrollbar {
    width: 10px;  
    }
    
    .card-visualizacao .card-overflow-visu::-webkit-scrollbar-track {
    background: #e0e9f0;  
    margin-top: 40px; 
    margin-bottom: 40px;  
    border-radius: 5px; 
    }
    
    .card-visualizacao .card-overflow-visu::-webkit-scrollbar-thumb {
    background-color: rgb(199, 199, 199);    
    border-radius: 20px;     
    }
    

.card-visualizacao .inf-card-visualizacao h1{
    font-size: 14px;
    margin-bottom: 2px;
}


.data-teste{
    color: rgb(124, 122, 122);
    margin-left: 5px;
    margin-right: 5px;
}


.card-overflow-visu2{
    background-color: #ebebeb;  
    margin-right: 5px;
    border-radius: 5px;
}

.vertical-tim{
    margin-bottom: 10px;
}
