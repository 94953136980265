.cont-log{
    border: 1px solid rgb(192, 192, 192);
    height: 200px;
    padding: 5px;
}

.cont-label{
    font-size: 14px;
    font-weight: normal;
}
