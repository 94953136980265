.Container{
  padding: 3px 30px; 
}

.user-details{
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  align-items: center; 
}

.user-details .input-itens span.details {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #222d32;
}

.listagem{
  max-height: 152px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: 20%;
  margin-right: 20%;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  margin-bottom: 10px; 
}

.listagem::-webkit-scrollbar {
  width: 10px;     
}

.listagem::-webkit-scrollbar-track {
  background: #e0e9f0;  
  margin-top: 10px; 
  margin-bottom: 10px;  
  border-radius: 5px; 
  
}

.listagem::-webkit-scrollbar-thumb {
  background-color: rgb(199, 199, 199);    
  border-radius: 20px;     
 
}

.input-itens {
  margin-bottom: 12px;
 width: 100%;
}

.input-itens span.details {
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
}

.user-details .input-itens input {
  height: 28px;
  width: 100%;
  outline: none;
  font-size: 14px;
  border-radius: 5px;
  padding-left: 2px;
  padding-right: 10px;
  border: 1px solid rgb(164, 163, 163);
  transition: all 0.3s ease;
  color: #455560;
  padding: 6px 6px;
}

.user-details .input-itens input:hover {
  border: 1px solid #111827;
}

.user-details .input-itens input:focus{
  border-color: #111827;
  box-shadow: 0 0 0.2em #111827;
  border-width: 1px;
}

.button_itens{
  height: 28px;
  width: 100%;
  padding: 0px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  background-color: #111827;
  margin-top: 21px;
  
}
  


@media (max-width: 750px) {
  .Container{
  display: grid;
  }
  .user-details .input-itens {

    width: 100%;
  }
  .Container{
    margin-left: 0%;
    width: 100%;
  }
  .button_itens{
    align-items: center;
    
  }
  .listagem{

    margin-left: 0%;
    margin-right: 0%;
  }
  .Container{
    padding: 0px 0px;
  }
}




