#input-box-ativos-cust1{
    width: 100%;
  }

 #input-box-ativos-cust1 input {
    background-color: #fff;
    -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
    -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    color: #12344D;
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
    display: inline-block;
    padding: 6px 6px;
    border-radius: 4px;
    border: 1px solid #92989e;
    -moz-transition: border linear 0.2s;
    -o-transition: border linear 0.2s;
    -webkit-transition: border linear 0.2s;
    transition: border linear 0.2s;
    width: 100%;
    border-width: 1px;
    height: 30px;
    margin-bottom: 4px;
    color: rgb(0, 0, 0);
}

 #input-box-ativos-cust1 input:hover {
    border: 1px solid #13293D;
}

 #input-box-ativos-cust1 input:focus {
    border: 2px solid #13293D;
}

 #input-box-ativos-cust1 span.details {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
    color: #12344D;
}

#container-cad-cust1 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    grid-template-areas: "content1 content2 content3 content4";
    grid-gap: 0.5rem;
    margin-bottom: 7px;
  }

  @media only screen and (max-width: 768px) {
    #container-cad-cust1 {
      grid-template-columns: 1fr;
          grid-template-areas:
            "content1"
            "content2"
            "content3"
            "content4";
            grid-gap: 0rem;
            margin-bottom: 5px;
    }
}

.card-painel{
  width: 100%;
  height: 120px;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 50px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ControleIcon-painel{
  font-size: 50px;
}

.descricao-painel{
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
}