.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;  
  justify-content: center; 
  background-color: rgba(0,0,0,0.5);
}

.modal-wrapper {
  margin-left: 16px ;
  margin-right: 16px ;
}

.ModalHeader{
margin-left: 10px;

}

.modal-ini {
  z-index: 100;
  background: white;
  position: relative;
  margin:20px  auto;
  max-width: 900px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: auto;
  border: 3px solid #13293D;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 4px 0;
  font-weight: bold;
  background-color: #13293D; 
}

.icone-modal {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  margin-right: 15px;
  color: rgb(255, 255, 255);
}

.icone-modal:hover {
  color: rgb(255, 0, 0);
}

.modal-body {
  overflow-y:auto;
  max-height: 540px;
  
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0;  
}


@media only screen and (max-width: 600px) {
  .modal-body {
    overflow-y:auto;
    max-height: 650px; 
  }
}