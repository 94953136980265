.title-login{
  text-align: left;
  font-size: 26px;
 color: #000000;
 width: 100%;
}
.div-title1{
  margin-bottom: 5px;
}

.title-bord{
font-weight: bold;
}

.title-bord-icon{
  font-weight: bold;
  font-size: 18px;
  }

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(45deg, #3962AC, #13293D);
  color: white;
}

.login-inf {
  width: 330px;
  height: 500px;
  background-color: #13293D;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.container-btn{
  margin-top: 40px;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.ativos-icon{
  margin-right: 10px;
  font-size: 20px;
}

.title-obs {
  font-size: 16px;
  font-weight: bold;
}

.contente-senha {
  width: 100%;
  text-align: right;
  margin-top: 5px;
  cursor: pointer;
}

.informativo {
  font-size: 16px;
  font-weight: bold;
}

.title-senha {
  color: #13293D;
  margin-right: 22px;
  font-weight: bold;
  font-size: 14px;
}

.title-senha:hover {
  color: #3962AC;
  cursor: pointer;
}

.login-right {
  background-color: white;
  width: 450px;
  height: 450px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}



.login-right h1 {
  font-size: 40px;
  margin-bottom: 40px;
  color: #13293D;
}

.login-right h4 {
  font-weight: 300;
  margin-top: 40px;
  font-size: 15px;
  font-weight: 500;
  color: #13293D;
}

.container-input{
margin-top: 50px;
}

.login-loginInputAmbiente {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 14px;
  border-style: solid;
  color: rgb(34,45,50);
  border-bottom: 2px solid;
}

.login-loginInputAmbiente svg {
  margin-left: 10px;
  font-size: 25px;
}

.login-loginInputAmbiente input {
  background: transparent;
  width: 100%;
  outline-width: 0;

  border: none;
  font-size: 17px;
  margin-left: 10px;
  margin-right: 10px;
}


.login-loginInputPassword {
  display: flex;
  align-items: center;
  color: rgb(34,45,50);
  width: 100%;
  height: 40px;
  margin-bottom: 14px;
  border-style: solid;
  border-bottom: 2px solid;
 
}

.login-loginInputPassword svg {
  margin-left: 10px;
  font-size: 25px;
}

.login-loginInputPassword input {
  background: transparent;
  width: 100%;
  outline-width: 0;
  border: none;
  font-size: 17px;
  margin-left: 12px;
  margin-right: 10px;
}

.login-right button {
  
  width: 40%;
  font-weight: 800;
  height: 40px;
  border-radius: 15px;
  font-size: 18px;
background-color: #fff;
  border-style: solid;
  color: rgb(34,45,50);
  border: 2px solid;
}

.login-right button:hover {
  background-color: #3962AC;
  color: #eff2f5;
  cursor: pointer;
}


.login-eye {
  align-items: center;
  justify-content: center;
  font-size: 30;
  cursor: pointer;
  margin-right: 10px;
}

.alerta{
  margin-top: 20px;
  color: red;
  font-weight: bold;
}

.logo{
  margin-bottom: 10px;
  width: 300px;
  height: 95px
}

@media screen and (max-width: 900px) {
  .login-right{
    border-radius: 20px;
  }

  .login-inf {
    display: none;
  }

  .container-login {
    width: 100%;
  }

  .login {
    padding-left: 10px;
    padding-right: 10px;
  }
}


.img-dash{
  width: 100%;
  margin-top: 20px;
}


.img-das3{
  width: 100%;
  margin-top: -10px;
}

.dash-info{
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.title-bord-icon-dash{
 font-size: 16px;
 font-weight: bold;
}

.dash-info-dash{
  width: 100%;
  text-align: center;
  margin-top: 30px;

}

.title-bord-icon-title{
  font-weight: bold;
  font-size: 18px;

}

.dash-info button{
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  background-color: #fff;
}

.dash-info button:hover{
  background-color:#3962AC;
  color: #fff;
}
