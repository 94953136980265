.featured-ProgressBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.top .title {
  font-size: 1.5rem;
  text-align: center;
}

.gauge-container {
  width: 100%; /* Ajusta a largura do contêiner */
  max-width: 400px; /* Limite máximo de largura */
  height: auto; /* Altura automática para manter a proporção */
}

.gauge-labels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px; /* Limite máximo de largura */
  margin-top: 10px;
  font-size: 0.9rem; /* Tamanho responsivo do texto */
}

@media (max-width: 768px) {
  .gauge-container {
    max-width: 300px; /* Reduz o tamanho em telas menores */
  }

  .gauge-labels {
    font-size: 0.8rem; /* Diminui o tamanho do texto */
  }

  .top .title {
    font-size: 1.2rem; /* Reduz o tamanho do título */
  }
}

@media (max-width: 480px) {
  .gauge-container {
    max-width: 200px; /* Ainda menor para telas de celular */
  }

  .gauge-labels {
    font-size: 0.7rem;
  }

  .top .title {
    font-size: 1rem; /* Ajuste adicional do título */
  }
}
