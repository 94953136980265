.container {
    width: '100%';
  }
  
  .accordion {
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .accordion-header {
    width: 100%;
    padding: 8px;
    font-size: 18px;
    cursor: pointer;
    border: none;
    background-color: #12344D;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .icon {
    font-size: 16px;
  }
  
  .accordion-content {
    padding: 10px;
    background-color: #f8f9fa;
    max-height: 150px;
    overflow-y: auto;
  }
  
  .item-accordion {
    padding: 5px;
    font-size: 16px;
    cursor: pointer;
    background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
    border: 1px solid rgb(192, 192, 192);
    margin-bottom: 5px;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  
  .item-accordion:hover {
    opacity: 0.8;
  }
  
  .etapasvalidationformulas2{
    display: flex;
    width: 100%;
    padding: 20px;
    background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
    border: 1px solid rgb(192, 192, 192);
    border-radius: 10px;

  }

    .add-etapasvalidationformulas2:hover{
      opacity: 0.7;
    }

  .etapasvalidationformulas2 h1{
    font-size: 14px;
    margin-top: 5px;
    margin-right: 10px;
    border-radius: 10px;
   
  }

  .add-etapasvalidationformulas2{
    background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
    border: 1px solid rgb(192, 192, 192);
    height: 30px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
  }

  .lab-etapasvalidationformulas2{
    margin-left: 10px;
  }