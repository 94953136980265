.container-modal-alert{
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    padding: 40px;
    display: block;
    min-height: 250px;
}

.div-alert{
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;  
}

.ControleIconAlert{
    font-size: 100px;
    color: #a5dc86;
     margin-bottom: 20px;
}


.ControleIconAlertcancel{
    font-size: 100px;
    color: #f27474;
     margin-bottom: 20px;
}

.info-alert{
    color: #545454;
    font-size: 18px;
    margin-bottom: 10px;
}

.btn-alert-div{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.btn-alert{
    margin-top: 20px;
    background-color: rgb(34, 45, 50);
    padding: 10px;
    border-radius: 5px;
    width: 50px;
    cursor: pointer;
    margin: 0 auto;
}

.btn-alert-esc{
  
    background-color: rgb(34, 45, 50);
    padding: 10px;
    border-radius: 5px;
    width: 50px;
    cursor: pointer;
    margin-right: 10px;
}

.btn-alert-dir{
  
    background-color: rgb(197, 7, 7);
    padding: 10px;
    border-radius: 5px;
    width: 50px;
    cursor: pointer;
    margin-left: 10px;
}

.btn-alert-dir:hover{
    opacity: 0.8;
}

.btn-alert-esc:hover{
    opacity: 0.8;
}

.btn-alert:hover{
    opacity: 0.8;
}

.btn-alert-lab{
    color: #fff;
}

.div-info-alert{
    margin-bottom: 20px;
}

.div-info-alert-atencao{
    margin-bottom: 20px;
    font-weight: bold;
   
}

.info-alert-atencao{
    font-size: 25px;
    color: #545454;
}