.featured {
  flex: 2;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #fff;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out; // Adiciona animação de transição

  .Badge{
    font-size: 12px;
    color: #666;
    transition: color 0.3s ease; /* Adiciona uma transição suave */
    margin-bottom: 10px;
    font-weight: normal;
  }

  &:hover {
    transform: translateY(-5px); // Elevação sutil ao hover
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.2); // Sombra mais forte ao hover
  }

  .top {
    .title2 {
      font-weight: bold;
      color: #666;
      font-size: 16px;

    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    .featuredChart {
      width: 150px;
      height: 150px;
    }




    .summary {
      width: 100%;
      display: flex;
      justify-content: space-around;
      gap: 10px;
      margin-top: -10px;
      .item {
        flex: 1;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.1); // Fundo semitransparente
        padding: 10px;
        border-radius: 12px;
        transition: background-color 0.3s;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2); // Fundo mais claro no hover
        }

        .itemTitle {
          font-size: 12px;
          color: #666;
          margin-bottom: 10px;
          font-weight: bold;
        }

        .itemResult {
          font-size: 18px;
          font-weight: bold;

          &.positive {
            color: #666; // Verde positivo
          }

          &.negative {
            color: #666; // Vermelho negativo
          }

          &.warning {
            color: #666; // Laranja intermediário
          }
        }
      }
    }
  }
}
