
.DragDropContext-ini{
    width: 100%;
    overflow-x:auto;
}


.DragDropContext-ini-exibir{
  width: 100%;
  overflow-x:auto;
  margin-top: 10px;
}

.Container-DragDrop{
    display: flex;
}

.TaskList{
 
    min-height: 100px;
    display: flex;
    flex-direction: column;
    background: #f3f3f3;
    width: 100%;
    border-radius: 5px;
    padding: 10px 10px;
    margin: 5px;
    min-width: 250px;
    border: 1px solid rgb(201, 201, 201);
    overflow-x:auto;
}


.Container-DragDrop-exibir{
  display: flex;
}

.TaskList-exibir{
  height: 300px;
  display: flex;
  flex-direction: column;
  background: #f3f3f3;
  width: 100%;
  border-radius: 5px;
  padding: 10px 10px;
  margin: 5px;
  min-width: 250px;
  border: 1px solid rgb(201, 201, 201);
}

.TaskList::-webkit-scrollbar {
    width: 12px;               /* width of the entire scrollbar */
    border-top-left-radius: 10px;
    border-radius: 20px;
    }
  
  .TaskList::-webkit-scrollbar-track {
    background: rgb(224, 219, 219);  
    border-radius: 20px;  

  }
  
  .TaskList::-webkit-scrollbar-thumb {
    background-color:   rgb(134, 134, 134);  
    border-radius: 20px;    
    border: 3px solid rgb(134, 134, 134);  
  
  }

.TaskColumnStyles{
    display: flex;
    width: 100%;
    height: 70vh;
}

.Title-DragDrop{
    color: #10957d;
    background: rgba(16, 149, 125, 0.15);
    padding: 2px 10px;
    border-radius: 5px;
    align-self: flex-start;
    margin-bottom: 5px;
}

 .btn-direcionamento-op{
  cursor: pointer;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  border: 1px solid rgb(192, 192, 192);
  display: flex;
  border-radius: 4px;
  width: 100%;
  height: 30px;
  text-align: center;
  margin-top: 10px;
}

.btn-direcionamento-op:hover{
  opacity: 0.7;
  border: 1px solid rgb(0, 0, 0);
}

.btn-direcionamento-op .text-direcionamento-op{
  text-align: center;
  font-size: 20px;
  width: 100%;
  margin-top: 3px;
}

.menu-trigger-op .dropOpcao-op{
  cursor: pointer;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  border: 1px solid rgb(192, 192, 192);
 display: flex;
 align-items: center;
 border-radius: 4px;
 width: 120px;
 padding: 10px;
 
}

.menu-trigger-op .dropOpcao-op:hover{
  border: 1px solid #000000;
  opacity: 0.8;
}

.dropOpcao-op h1{
  font-size: 14px;
  margin-left: 10px;
}

.select-box-ativos-select-op{
  margin-top: 10px;
}

.select-box-ativos-select-op-alt{
  margin-top: 19px;
}


.details-op{
  padding-top: 10px;
}

.div-input-op{
  display: flex;
}

.icon-filter-op{
  font-size: 20px;
  margin-top: 2px;
  cursor: pointer;
}


.icon-filter-op:hover{
  opacity: 0.6;
}

.div-icon-op{
  display: flex;
  justify-content: space-between;
  height: 22px;
}



.btn-direcionamento-op-alt{
  cursor: pointer;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  border: 1px solid rgb(192, 192, 192);
  display: flex;
  border-radius: 4px;
  width: 100%;
  height: 30px;
  text-align: center;
  margin-top: 15px;
}

.btn-direcionamento-op-alt:hover{
  opacity: 0.7;
  border: 1px solid rgb(0, 0, 0);
}

.btn-direcionamento-op-alt .text-direcionamento-op-alt{
  text-align: center;
  font-size: 20px;
  width: 100%;
  margin-top: 3px;
}

