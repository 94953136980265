.Coluna-itens {
  padding: 7px;
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 14px;
  padding-left: 7px;
  align-items: center;
  overflow-y: auto;
  overflow-x: auto;
}

.TR-Coluna-itens{
  padding: 10px;
}

td{
word-break: break-all;
}

.TR-Coluna-itens:hover {
  background-color: #f5f5f5;
}

tr {
  transition: all 0.2s ease-in;
  font-size: 16px; 
}

 .Coluna-icone-itens{
  padding: 3px;
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 12px;
  align-items: center;
  text-align: center;
}

.icon-delete-itens {
  padding: 5px;
  background: #ffa500;
  cursor: pointer;
  border-radius: 3px;
  align-items: center;
}

.icone-itens:hover {
  opacity: .7;
}

.icon-col{
  padding-top: 2px;
}