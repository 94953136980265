.Coluna-status{
  border: 1px solid #d6d3d3;
  border-collapse: collapse;
  font-size: 13px;
  min-width: 140px;
  max-width: 140px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.cor-cartao-preparacao{
  width: 100%;
  background-color:  #d6d3d3;
  text-align: center;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
}


.cor-cartao-amarelo{
  width: 100%;
  background-color:  rgb(255, 165, 0);
  text-align: center;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
}

.cor-cartao-vermelho{
  width: 100%;
  background-color:  rgb(255, 165, 0);
  text-align: center;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
}

.cor-cartao-verde{
  width: 100%;
  background-color: #3cd849;
  text-align: center;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 3px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
}

.btn-visu{
  border-collapse: collapse;
  font-size: 13px;
  min-width: 100px;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #d6d3d3;
  cursor: pointer;
}


.btn-inv{
  font-size: 13px;
  border: 2px solid #13293D;
  padding: 4px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.btn-inv:hover{
    background-color: #13293D;
    color: #fff;
}