
  
.listagem-emp{
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px; 
  width: 100%;
}
