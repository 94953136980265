  
  .btn-activity{
  font-size: 15px;
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  width: 100%;
  border: 1px solid rgb(102, 102, 102);
  cursor: pointer;
  border-radius: 5px;
  height: 30px;
  }
  
  .btn-activity:hover {
  border: 1px solid #aaa7a7;
  opacity: 0.8;
  }

  .icon-ok-btn{
    color: #0adb49;
  }

  .icon-cancelar-btn{
    color: #ff0000;
  }

  .details-esc-ativo.disabled {
    pointer-events: none;
    color: gray;
    cursor: not-allowed;
  }
  