.Coluna-GridInputid {
    border: 1px solid #d6d3d3;
    border-collapse: collapse;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
    max-width: 70px; 
    min-width: 70px;
  }

  .Coluna-GridInput {
    border: 1px solid #d6d3d3;
    border-collapse: collapse;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
    max-width: 200px; 
    min-width: 200px;
  }


  .Coluna-GridInputdesc{
    border: 1px solid #d6d3d3;
    border-collapse: collapse;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
    max-width: 250px;
    min-width: 250px;
  }

  .ColunaCheck-input {
    padding: 4px;
    border: 1px solid #d6d3d3;
    border-collapse: collapse;
    font-size: 12px;
    padding-top: 5px;
    align-items: center;
    min-width: 20px;

  }


  .ColunaCheck-input input{
    width: 100%;
  }


  .grid-tab{
    max-height: 400px;
    overflow-y: auto;
    overflow-x: auto;
    min-height: 300px;
    padding-bottom: 80px;
  }
  
  .grid-tab::-webkit-scrollbar {
    width: 10px;
   
  }
  
  .grid-tab::-webkit-scrollbar-track {
    background: #e0e9f0;  
    margin-top: 10px; 
    margin-bottom: 10px;  
    border-radius: 5px; 
    
  }
  
  .grid-tab::-webkit-scrollbar-thumb {
    background-color: rgb(199, 199, 199);    
    border-radius: 20px;     
  }

  .spa-aten{
    align-items: center;
    text-align: center;
     background-color: #f3bebe;  
      padding-left: 6px;
      padding-right: 6px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 10px;
      border-radius: 6px;
      color: #824242;
      font-size: 16px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      font-weight: normal;
      margin-bottom: 20px;
    }

    .input-grid-input{
      background-color: #fff;
      -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
      -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
      box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
      color: #12344D;
      font-size: 14px;
      font-weight: 500;
      box-sizing: border-box;
      display: inline-block;
      border-radius: 4px;
      border: 1px solid #92989e;
      -moz-transition: border linear 0.2s;
      -o-transition: border linear 0.2s;
      -webkit-transition: border linear 0.2s;
      transition: border linear 0.2s;
      width: 100%;
      border-width: 1px;
      height: 30px;
      padding-left: 4px;
      padding-right: 4px;
      font-size: 14px;

      min-width: 130px;
    }
    
    .input-grid-input:hover {
      border: 1px solid #13293D;
    }
    
    .input-grid-input:focus {
      border: 1px solid #13293D;
    }

    .select-grid-s{
      width: 100%;
    }