/* DualListBox.css */
.dual-listbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.listbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.search-box {
  padding: 5px;
  font-size: 14px;
}

.list {
  width: 100%;
  height: 200px;
  overflow-y: auto;
  margin-top: 5px;
  border: 1px solid #CCCCCC;

}


.buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 10px 0;
}

.btn-move {
  width: 40px;
  height: 30px;
  padding: 5px;
  border: 1px solid #CCCCCC;
  background-color: #fff;
}

.option-list{
 font-weight: bold;
  border: 1px solid rgb(201, 201, 201);
  border-radius: 5px;
  margin: 8px;
  padding: 6px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
}




@media (min-width: 768px) {
  .dual-listbox {
    flex-direction: row;
  }

  .listbox {
    width: 45%;
  }

  .buttons {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    margin-top: 40px;
  }
}
