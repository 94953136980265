.listContainer {
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 20px;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    height: 400px;
}

.alin-listContainer{

width: 100%;
height: 300px;
align-items: center;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
}

.icon-tab{
    display: flex;
}

.icon-filter{
    font-size: 22px;
    cursor: pointer;
    margin-left: 5px;
}

.icon-filter:hover{
    opacity: 0.5;
}

.cont-listTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .cont-listTitle .listTitle {
    width: 100%;
    font-weight: 500;
    color: gray;
    margin-bottom: 15px;
    font-size: 16px;
    font-size: 16px;
    color: #666;
    background-color: #fff;
    font-weight: bold;
  }

.container-BarChart{
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    padding: 20px;
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
}
