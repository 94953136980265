/*----------------------------TAB INI------------------------- */

.content-planejador{
    background: #fff;
  }
  
   .container-tab {
       display: flex;
       flex-direction: column;
       position: relative;
       width: 100%;
       margin-top: 20px;
       height: 369px;
   }
  
   .bloc-tabs {
       display: flex;
       background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  
   }
  
   .tabs {
       text-align: center;
       width: 50%;
       height: auto;
       background: rgba(128, 128, 128, 0.075);
       cursor: pointer;
       border-bottom: 1px solid rgba(0, 0, 0, 0.274);
       box-sizing: content-box;
       position: relative;
       outline: none;
  
   }
  
   .tabs:not(:last-child) {
       border-right: 1px solid rgba(0, 0, 0, 0.274);
       
   }
  
   .active-tabs {
       background: white;
       border-bottom: 1px solid transparent;    
   }
   .active-tabs h1{
  color:#139c31
   }
  
   .active-tabs::before {
       content: "";
       display: block;
       position: absolute;
       top: -2px;
       left: 50%;
       transform: translateX(-50%);
       width: calc(100% + 2px);
       height: 2px;
       background: #222d32;
       
   }
  
  
   .content-tabs {
       flex-grow: 1;
   }
  
   .content {
       background: white;
       padding-top: 15px;
       padding-bottom: 10px;
       padding-left: 20px;
       padding-right: 20px;
       width: 100%;
      display: none;
     
   
   }
  
   .active-content {
       display: block;
       
   }
  
   .containertab-1 {
       display: grid;
       width: 100%;
       grid-template-columns: 0.2fr 1fr 0.2fr;
       grid-template-areas: "content1 content2 content3 ";
       grid-gap: 0.1rem;
   }
  
   #content-tab1 {
       grid-area: content1;
       border-radius: var(--main-radius);
       padding-top: var(--main-padding);
       flex-wrap: wrap;
       justify-content: space-between;
       font-size: 14px;
       color: #455560;
       display: flex;
       flex-direction: row;
       justify-content: center;
       align-items: center;
       margin-bottom: 7px;
   }
  
   #content-tab2 {
       grid-area: content2;
       border-radius: var(--main-radius);
       padding-top: var(--main-padding);
       flex-wrap: wrap;
       justify-content: space-between;
       font-size: 14px;
       color: #455560;
       display: flex;
       flex-direction: row;
       justify-content: center;
       align-items: center;
       padding-left: 5px;
       padding-right: 5px;
  
   }
  
   #content-tab3 {
       grid-area: content3;
       border-radius: var(--main-radius);
       padding-top: var(--main-padding);
       font-size: 14px;
       color: #455560;
       max-height: 560px;
       display: flex;
       flex-direction: row;
       justify-content: center;
       align-items: center;
   }
  
  
   .icone-Modal {
       font-size: 20px;
       color: #455560;
       margin-top: 5px;
       margin-left: 5px;
   }
  
  
   #content-tab2 h1 {
       font-size: 14px;
       color: #222d32;
   }
  
   /*----------------------------TAB FIM------------------------- */

   .novo-chamado{
 
   }

   .btn-status{
    width: 100%;
    height: 26px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;

  }
  .btn-sel-dir{
    width: 15%;
    min-width: 100px;
    height: 26px;
    background-color: #3CB371;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    cursor: pointer;
    box-shadow: 0px 0px 8px 0px rgba(50, 50, 50, 0.514);
  }  

  .btn-sel-dir:hover{
    opacity: 0.8;
    }

    .titulo-status{
        font-size: 16px;
        text-align: center;
        color: #FFF;
        margin-top: 4px;
      
      }

      .btn-sel-esc{
        width: 15%;
        height: 26px;
        min-width: 100px;
        background-color: #676666;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        cursor: pointer;
        box-shadow: 0px 0px 8px 0px rgba(50, 50, 50, 0.514);
      }

      .btn-sel-esc:hover{
        opacity: 0.8;
        }

        .btn-add{
            width: 160px;
            margin-top: 10px;
            margin-left: 20px;
            padding: 5px;
            border-radius: 5px;
            background-color: #222d32;
            color: #fff;
            font-weight: bold;
          }
          
          .btn-add:hover{
            opacity: 0.8;
          }

   @media (max-width: 1000px) {
    .btn-status{
        margin-top: 20px;
      }
      .btn-sel-dir{
        width: 30%;
      }
      .btn-sel-esc{
        width: 30%;
      }
   }


   .list-tichet{
    max-height: 350px;
    overflow-y: auto;
    padding-right: 2px;
   }

   .ticket-exibir{
    font-size: 16px;
    color: #000;
    text-align: center;
    margin-top: 20px;
   }

   .lab-step{
    font-size: 12px;
    font-weight: bold;
   }

   
.circle-ticket {
  width: 45px;
  height: 45px;
  line-height: 25px;
  background-color: #13293D;
  color: white;
  border-radius: 50%;
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  
}

.stepWrapper {
  display: flex;
  margin-bottom: 20px;
}

.stepBlock .circleWrapper {
  padding: 0px 35px;
  position: relative;

}

.selected .circle-ticket {
  color: black;
  background-color: white;
  border-color: #13293D;
}

.stepBlock:not(:last-child) > .circleWrapper::after {
  content: '';
  width: 100%;
  margin-left: 5px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  background-color: #13293D;
  margin: auto;
  z-index: -1;
}

.stepBlock:not(:last-child) > .circleWrapper::after {
  height: 3px;
}

.stepBlock:not(:last-child).selected > .circleWrapper::after, .stepBlock:not(:last-child).selected ~ .stepBlock:not(:last-child) > .circleWrapper::after {
  height: 2px;
  opacity: 0.6;
}

.primaryButton {
  padding: 10px 25px;
  background-color: #13293D;
  border: none;
  margin: 10px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.primaryButton:disabled {
  opacity: 0.5;
}

.stepBlock{
  text-align: center;
}




.spa-aten-ticket{
    align-items: center;
    text-align: center;
    background-color: #f3bebe;  
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 10px;
    border-radius: 6px;
    color: #824242;
    font-size: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    font-weight: normal;
    margin-bottom: 10px;
  }

@media only screen and (max-width: 710px) {
  .circle-ticket {
    width: 25px;
    height: 25px;
    margin-left: 5px;
    font-size: 12px;
  }

  .stepBlock .circleWrapper {
    padding: 0px 20px;
    position: relative;
  
  }
  
  .lab-step{
    font-size: 10px;
   }

}

.container-modal-incluir2{

}