.DualListBox{
  margin-top: 20px;
}

.div-info-fieldset {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
}

.div-info-legend {
  padding: 0 10px;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.div-info-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.div-info-permission-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.div-info-permission-title {
  font-weight: bold;
  margin-bottom: 5px;
  color: #333333;
}

.div-info-label {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.div-info-checkbox {
  cursor: pointer;
  transform: scale(1.2);
}
