.setar-icon{
  border: 2px solid #92989e;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  display: flex;               
  justify-content: center;      
  align-items: center;
  cursor: pointer;
}

.details-list {
    display: block;
    font-weight: bold;
    color: #222d32;
    font-size: 12px;
    margin-bottom: 10px;
   
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Três colunas */
  gap: 16px; /* Espaçamento entre os ícones */
}

.icon-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.icon-item.selected {
  border-color: #12344D; /* Destaque para o ícone selecionado */
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  margin: 0 10px;
  padding: 5px 10px;
}

.lab-icon{
  display: block;
  font-weight: 500;
  margin-bottom: 5px;
  color: #12344D;
}