.title-login2{
  font-size: 22px;
  text-align: left;
 color: #000000;
 width: 100%;
}

.forgot-password {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: linear-gradient(45deg, #3962AC, #13293D);
  color: white;
}

.forgot-password-right {
  background-color: white;
  width: 450px;
  height: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.forgot-password-inf {
  width: 330px;
  height: 500px;
  background-color: #13293D;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.container-input2 {
  margin-top: 30px;
}

.forgot-password-inputEmail {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 14px;
  border-style: solid;
  border-bottom: 2px solid;
  color: rgb(34, 45, 50);

}

.forgot-password-inputEmail svg {
  margin-left: 10px;
  font-size: 25px;
}

.forgot-password-inputEmail input {
  background: transparent;
  width: 100%;
  outline-width: 0;
  border: none;
  font-size: 17px;
  margin-left: 10px;
  margin-right: 10px;
}

.container-btn {
  margin-top: 40px;
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
}

.forgot-password-right button {
  width: 40%;
  font-weight: 800;
  height: 40px;
  border-radius: 15px;
  font-size: 18px;
  background-color: #fff;
  border: 2px solid rgb(34, 45, 50);
  color: rgb(34, 45, 50);
}

.forgot-password-right button:hover {
  background-color: #3962AC;
  color: #eff2f5;
  cursor: pointer;
  border: 0px solid rgb(34, 45, 50);
}

.img-das3 {
  width: 100%;
  margin-top: -10px;
}

.img-dash {
  width: 100%;
  margin-top: 20px;
}

.dash-info-dash {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.title-bord-icon-dash {
  font-size: 16px;
  font-weight: bold;
}


@media screen and (max-width: 900px) {

  .forgot-password-inf {
    display: none;
  }

  .forgot-password-right{
    border-radius: 20px;
  }

}

.icon-input{
  margin-top: -2px;
}