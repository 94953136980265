.monthly-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px; /* Adiciona padding para evitar que o conteúdo fique muito próximo das bordas */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
  border: 1px solid rgb(192, 192, 192);
  border-radius: 5px;
  padding: 10px;
}

.header-mes{
  color: #000;
  font-size: 16px;
}

.body {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Ajusta o número de colunas conforme o tamanho da tela */
  gap: 10px;
  width: 100%;
  max-width: 1000px; /* Define um limite máximo para a largura do body */

}

.day {
  border: 1px solid rgb(192, 192, 192);
  padding: 5px;
  min-height: 100px;
  background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
  box-sizing: border-box; /* Inclui padding e border no cálculo da largura e altura */
  border-radius: 5px;
}

.day-header {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
  text-transform: capitalize;

}

.day-body {
  min-height: 80px;
}

.activity {
  padding: 5px;
  margin-bottom: 5px;
  background-color: #dfe6e9;
  border-radius: 4px;
  cursor: auto; /* Remover o ícone de mover */
  font-size: 14px; /* Ajuste o tamanho da fonte para melhor legibilidade */
}

.week-days-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.week-day {
  font-weight: bold;
  padding: 5px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.calendar-btn{

  display: flex;
  gap: 10px; /* Espaçamento entre os botões */
}

.btn-calendar{
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
  border: 1px solid rgb(192, 192, 192);
  border-radius: 5px;
  cursor: pointer;
 height:40px;
 width: 35px;
} 

.btn-calendar:hover {
  opacity: 0.6;
  }

.btn-calendar-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
  border: 1px solid rgb(192, 192, 192);
  border-radius: 5px;
  cursor: pointer;
  width: 70px;
}

.btn-calendar-label:hover {
opacity: 0.6;
}

.calendar-btn-icon{
  height: 100%;
  font-size: 14px;

}

.btn-calendar-label-mes{
  font-size: 12px;
}

.btn-edt-cal{
  background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
  border: 1px solid rgb(211, 211, 211);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.btn-edt-cal:hover{
  opacity: 0.7;
}

/* Media queries para telas menores */
@media (max-width: 768px) {

  .body {
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr)); /* Ajusta o tamanho das colunas em telas menores */
  }

  .day-header {
    font-size: 12px;
  }

  .activity {
    font-size: 12px;
  }
}

@media (max-width: 480px) {

  .body {
    grid-template-columns: repeat(auto-fit, minmax(60px, 1fr)); /* Ajusta ainda mais o tamanho das colunas */
  }

  .day {
    padding: 5px;
    min-height: 80px;
  }

  .day-header {
    font-size: 10px;
  }

  .activity {
    font-size: 10px;
    padding: 3px;
  }
}
