.profile-page {
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
}

.tabs-conf {
  display: flex;

  width: 100%;
  background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
  border: 1px solid rgb(192, 192, 192);
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  font-weight: bold;
}

.tab-button.active {
  border-bottom: 2px solid #12344D;
  color: #12344D;
}

.tab-panel {
  padding: 20px 0;
}

.dados-container{

width: 100%;
padding-right: 20px;
}

.foto-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;

}

.foto-perfil {
  border-radius: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.logo-perfil {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 250px;
  height: 140px;
 background-color: #dddbdb;
 border-radius: 10px;
}

.alterar-foto-btn {
  background-color: #fff;
  border: 1px solid #12344D;
  color: #12344D;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.alterar-foto-btn:hover {
  background-color: #12344D;
  color: #fff;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex;
  justify-content: flex-start; /* Alinha o botão à esquerda */
  margin-top: 10px;
}

.save-button {
  background-color: #12344D; /* Verde */
  color: white; /* Cor do texto */
  padding: 12px 50px; /* Espaçamento interno */
  border: none; /* Remove a borda */
  border-radius: 5px; /* Arredonda os cantos */
  cursor: pointer; /* Cursor pointer para indicar que é clicável */
  font-size: 16px; /* Tamanho da fonte */
  font-weight: bold; /* Negrito */
}

.save-button:hover {
  background-color: #112431; /* Um verde um pouco mais escuro ao passar o mouse */
}


.button-container {
  display: flex;
  gap: 10px; /* Define o espaço entre os botões */
}

.alterar-foto-btn {
  width: 105px;
  cursor: pointer;
}
