.btn-busca-movimentacoes{
    width: 100%;
    background-color: #13293D;
    color:#fff;
    height: 30px;
    margin-top: 23px;
    text-align: center;
    padding-top: 4px;
    border-radius: 5px;
    cursor: pointer;
    
    }

    .lab-movimentacao{
        font-size: 20px;
        }

        