.card-visualizacao{
    width: 100%;
 
}

.card-visualizacao .titulo-card-visuali{  
        text-align: center;
        background-color: #12344D;
        border-radius: 6px;
        color: #ffffff;
        font-size: 16px;
        box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
        margin-bottom: 20px;  
        padding: 5px;
}

.card-visualizacao .inf-card-visualizacao{
    border-left: 3px solid #13293D;
    padding-left: 20px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    padding-top: 10px;
 
}

.card-visualizacao .card-overflow{
    overflow-y:auto;
    max-height: 340px;
}


.card-visualizacao .card-overflow::-webkit-scrollbar {
    width: 10px;  
    }
    
    .card-visualizacao .card-overflow::-webkit-scrollbar-track {
    background: #e0e9f0;  
    margin-top: 40px; 
    margin-bottom: 40px;  
    border-radius: 5px; 
    }
    
    .card-visualizacao .card-overflow::-webkit-scrollbar-thumb {
    background-color: rgb(199, 199, 199);    
    border-radius: 20px;     
    }
    

.card-visualizacao .inf-card-visualizacao h1{
    font-size: 14px;
    margin-bottom: 2px;
}


@media only screen and (max-width: 600px) {
    .card-visualizacao .titulo-card-visuali{  
        font-size: 14px;
    }
}