.icon404{
    font-size: 100px;
    color: #13293D;
    margin-top: 80px;
}

.containerPrincipalpage{

    align-items: center;
    text-align: center;

    
}

.containerPrincipalpage2{
    width: 50%;
    align-items: center;
    text-align: center;
}


.titlepage1{
    margin-top: 15px;
    color: #13293D;
    font-size: 40px;
}

.titlepage2{
    margin-top: 10px;
    color: #7a7a7a;
    font-size: 25px;
   
}

@media only screen and (max-width: 750px) {

    .titlepage1{
        margin-top: 15px;
        color: #13293D;
        font-size: 20px;    
    }
    
    .titlepage2{
        margin-top: 15px;
        color: #7a7a7a;
        font-size: 16px;
    }
 
  }