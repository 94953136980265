.donut-chart-container {
    width: 100%;
    height: auto;
    padding: 20px; /* Ajuste o padding para reduzir o espaçamento em volta */
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    
  }

  .donut-chart-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.2); 
  }
  
  .donut-chart-container:hover {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  }
  
  /* Estilo do título */
  .donut-chart-title {
    margin: 0; /* Remova o espaçamento extra em cima e embaixo */
    padding-bottom: 10px; /* Pequeno espaçamento abaixo do título */
    text-align: left; /* Alinhe o texto à esquerda */
    font-weight: bold;
    color: #666;
    font-size: 16px;
  }
  
  /* Ajuste o layout do conteúdo do gráfico */
  .donut-chart-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 10px; /* Espaço entre o título e o conteúdo do gráfico */
  }
  
  .donut-chart-legend {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 20px;
    flex: 1;
  }
  
  .donut-chart-legend-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
  }
  
  .donut-chart-legend-color-box {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 8px;
  }
  
  .donut-chart-legend-item:hover {
    color: #333;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .donut-chart-content {
      flex-direction: column;
      align-items: center;
      height: auto;
    }
  
    .donut-chart-legend {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  