.loader {
    border: 16px solid #cccaca;
    border-radius: 50%;
    border-top: 16px solid #455560;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-left:46%;
  }

  .container-loader{
    display: block;
    align-items: center;
    text-align: center;
    margin-top: 60px;
  }

  .loader-text{
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
    color: #9e9d9d;
    -webkit-animation: btn-pisca .9s linear infinite;
    -moz-animation: btn-pisca .9s linear infinite;
    -ms-animation: btn-pisca .9s linear infinite;
    -o-animation: btn-pisca .9s linear infinite;
    animation: btn-pisca .9s linear infinite;
 
  }
  
  @keyframes btn-pisca {
    0% { opacity: 0; }
    50% { opacity: 0.9; }
    100% { opacity: 1; }
}
.btn-pisca {
  text-align: center;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  color: #aca9a9;
  padding: 0.6em 0.8em;
  display: inline-block;
  text-decoration: none;
  border-radius: 4px; 
  -webkit-animation: btn-pisca .9s linear infinite;
  -moz-animation: btn-pisca .9s linear infinite;
  -ms-animation: btn-pisca .9s linear infinite;
  -o-animation: btn-pisca .9s linear infinite;
  animation: btn-pisca .9s linear infinite;
}

  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }