
  .cad-prod-tab{
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding-top: 20px;
    width: 100%;
}

  .react-tabs {
    display: flex;
    border-radius: 10px;
    width: 100%;
    color: white;
    background: #ffffff;
    padding: 10px;
  }
  
  .react-tabs__tab-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
    padding: 0;
    color: white;
    background: #ffffff;
    margin-right: 30px;
  }
  
  .react-tabs__tab {
    height: 30px;
    list-style: none;
    cursor: pointer;
    color: rgb(95, 95, 95);
    padding-top: 6px;
    border-radius: 5px;
    margin-bottom: 2px;
    font-weight: bold;
    width: 100%;
    min-width: 170px;
  }
  
  .react-tabs__tab:hover {
    background: #f5f4f4;
  }

  .react-tabs__tab--selected {
    background-color: #ececec;
    box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
    border-color: #1c1d1f;
    border-left: 4px solid #13293D;
    color: rgb(0, 0, 0);
  }
  
  .react-tabs__tab-panel {
    display: none;
    width: 100%;

  }
  
  .react-tabs__tab-panel--selected {
    display: block;

  }
  
  .react-tabs__tab {
    padding-left: 24px;
 
  }
  
  .react-tabs__tab--selected {
    padding-left: 21px;
  }
  
  .panel-contentComp {
    width: 100%;
    overflow-y: auto;
  }
  .panel-content {
    color: black;
    width: 100%;
    overflow-y: auto;
  }

  .panel-content-visu{
    display: flex;
    font-size: 10px;
    margin-bottom: 8px;
    font-weight: normal;
  }

  .panel-content-visu-qrcode{
    display: flex;
    font-size: 10px;
    margin-bottom: 5px;
    font-weight: normal;
  }



.cad-prod-tab .titulo-card-visu{  
    text-align: center;
        background-color: #13293D;
        box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
        padding-left: 6px;
        padding-right: 6px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 10px;
        border-radius: 6px;
        color: #ffffff;
        font-size: 18px;
        margin-left: 10%;
        margin-right: 10%;
        margin-bottom: 10px;
}


.resumo-bd{
  margin-left: 6px;
  font-weight: normal;
}

.panel-content-visu-aviso .panel-content-aviso{
font-size: 16px;
font-weight: normal;
margin-top: 20px;
}

 .panel-content-aviso-atv{
font-size: 16px;
font-weight: normal;
margin-top: 20px;
}

.panel-content-visu-aviso .panel-content-aviso-add{
  font-size: 16px;
  color: #13293D;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 20px;
}

.resumo-bd-titulo{
  background-color: #13293D;
 
}

.resumo-titulo{
  color: '#fff'
}

.panel-content-visu-aviso .panel-content-aviso-add:hover{
  color: #8f8f8f;
}

.panel-content-visu-aviso{
  display: flex;
  font-size: 10px;
  margin-bottom: 5px;
  font-weight: normal;

}



.qual-grid{
  padding-top: 10px;
}


.container-InventarioEdit{
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 1.8fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 10px;
}

.container-InventarioEdit2{
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 10px;
}

.container-InventarioEdit3{
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .container-InventarioEdit  {
    grid-template-columns: 1fr;
    grid-template-areas:
        "content1"
        "content2"
        "content3";
    grid-gap: 0rem;
    margin-bottom: 5px;
}
.container-InventarioEdit2 {
  grid-template-columns: 1fr;
  grid-template-areas:
      "content1"
      "content2";
  grid-gap: 0rem;
  margin-bottom: 5px;
}
.container-InventarioEdit3 {
  grid-template-columns: 1fr;
  grid-template-areas:
      "content1"
      "content2"
      "content3";
  grid-gap: 0rem;
  margin-bottom: 5px;
}
.btn-busca-edt{
  margin-bottom: 10px;
  margin-top: 10px;
}
}


.btn-busca-edt{
  width: 100%;
  background-color: #13293D;
  color:#fff;
  height: 30px;
  margin-top: 21px;
  text-align: center;
  padding-top: 4px;
  border-radius: 5px;
  cursor: pointer;
  }


  .btn-busca-edt:hover{
    opacity: 0.6;
  }


  .btn-qrcode{
    background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
    border: 1px solid rgb(192, 192, 192);
    height: 25px;
    width: 35px;
    margin-top: -5px;
    margin-left: 10px;
   font-size: 22px;
   display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  }

  .btn-qrcode:hover{
    opacity: 0.6;
  }

  .modal-visu-qrcode{
  
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .lab-icone-pln{
    margin-left: 10px;
  }

  .input-c input {
    height: 18px;
    width: 18px;
    outline: none;
    font-size: 14px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 2px;
    border: 1px solid rgb(164, 163, 163);
    transition: all 0.3s ease;
    color: #222d32;
  }

  
 .input-c {
    display: flex;
    padding-bottom: 6px;
 
  }

.input-c .details{
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
  margin-top: 1px;
}

.planner-panel{
  width: 100%; 
  padding: 20px;
  border: 1px solid rgb(164, 163, 163);
}

.div-grid-loc{

}

.btn-flex{
  display: flex;
  margin-top: 20px;
  padding-top: 20px;
}

.btn-alert-lab-flex{
  margin-top: 20px;
  background-color: rgb(34, 45, 50);
  padding: 10px;
  border-radius: 5px;
  width: 50px;
  cursor: pointer;
  margin: 0 auto;
  color: #fff;
}

.btn-alert-lab-flex:hover{
  opacity: 0.8;
}


.ControleIconAlertquestion{
  font-size: 100px;
  color: #f8f53a;
   margin-bottom: 20px;
}

