/* Botao.css */
.botao-resultado-formula {
    background-color: #12344D; /* Verde */
    color: white; /* Texto branco */
    padding: 10px 20px; /* Espaçamento interno */
    border: none; /* Sem borda */
    border-radius: 5px; /* Bordas arredondadas */
    cursor: pointer; /* Cursor de ponteiro */
    font-size: 16px; /* Tamanho da fonte */
    transition: background-color 0.3s; /* Transição suave */
    margin-right: 10px;
}

.botao-resultado-formula2 {
    color: rgb(0, 0, 0); /* Texto branco */
    padding: 10px 20px; /* Espaçamento interno */
    border: none; /* Sem borda */
    border-radius: 5px; /* Bordas arredondadas */
    cursor: pointer; /* Cursor de ponteiro */
    font-size: 16px; /* Tamanho da fonte */
    transition: background-color 0.3s; /* Transição suave */
    margin-right: 10px;
    background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
    border: 1px solid rgb(192, 192, 192);
}


.botao-resultado-formula2:hover {
    opacity: 0.8;
}


.botao-resultado-formula:hover {
    opacity: 0.8;
}


.container-modal-incluircontainer2{
    display: flex;
    align-items: center;
    padding: '20px';
}

.resultado-formula{
    width: '100%';
    border: 1px solid #12344D;
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    height: 100px;        /* Altura fixa */
    overflow-y: auto;     /* Ativa a rolagem vertical se o conteúdo exceder 100px */
    display: flex;
    flex-wrap: wrap;      /* Organiza os elementos em múltiplas linhas */
    align-content: flex-start; /* Garante que os itens se alinhem ao topo */
}

.resultado-formula2{
    width: '100%';
    border: 1px solid #12344D;
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    height: 150px;
}

.valor-resultado-formula{
    color: #000;
    font-size: 16px;
    font-weight: bold;
    margin-right: 5px;
}

.btn-resultado-formula{
    display: flex;
}
.break {
    width: 100%;
    height: 0;
  }
  
  .Bt-button-cad-atv{
    width: 100%;
  }