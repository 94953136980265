.featured-ProgressBar {
  flex: 2;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }

  .container-progressbar {
    max-height: 230px;
    overflow-y: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #3962AC;
      border-radius: 10px;
    }
  }

  .ProgressBar {
    margin-bottom: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
      border-bottom: none;
    }

    .ProgressBar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      padding-bottom: 5px;

      .title-desc {
        font-size: 12px;
        font-weight: 700;
        color: #666;
      }

      .porcentagem-text {
        font-size: 12px;
        font-weight: bold;
        color: #666;
      }
    }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      font-size: 16px;
      color: #666;
      background-color: #fff;
      font-weight: bold;
    }
  }
}


.featured-ProgressBar2 {
  flex: 2;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }

  .container-progressbar {
    max-height: 230px;
    overflow-y: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #3962AC;
      border-radius: 10px;
    }
  }

  .ProgressBar {
    margin-bottom: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid #e0e0e0;

    &:last-child {
      border-bottom: none;
    }

    .ProgressBar-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      padding-bottom: 5px;

      .title-desc {
        font-size: 12px;
        font-weight: 700;
        color: #666;
      }

      .porcentagem-text {
        font-size: 12px;
        font-weight: bold;
        color: #666;
      }
    }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      font-size: 16px;
      color: #666;
      background-color: #fff;
      font-weight: bold;
    }
  }
}