/* Container Principal */
.img-dash10{
  width: 200px;
  height: 80px;
}

.inf-principal{
  color: #000000;
}



#content1 {
  flex: 0 0 auto;              /* Mantém a largura da imagem */
}

#content2 {
  flex-grow: 1;                /* Permite que o conteúdo ocupe o espaço restante */
  display: flex;               /* Para centralizar o título */
  justify-content: center;      /* Centraliza horizontalmente */
}

.title-report {
  text-align: center;          /* Centraliza o texto do título */

}

.title-report-text {
  margin: 0;                  /* Remove margem padrão do título */
  color: #000000;
  font-size: 28px;
  margin-bottom: 20px;
  margin-top: -5px;
}


.header {
  display: flex;
  align-items: center; /* Alinha verticalmente no centro */
  justify-content: center; /* Centraliza horizontalmente */
  position: relative; /* Para usar posicionamento absoluto se necessário */
  color: #000000;
}

.icon-report {
  position: absolute; /* Remove o elemento do fluxo normal para alinhá-lo à esquerda */
  left: 20px; /* Ajuste conforme necessário */
  color: #000000;
}

.header-title {
  margin: 0; /* Remove margem padrão */
  text-align: center; /* Centraliza o texto */
  flex-grow: 1; /* Permite que o título ocupe o espaço restante */
  color: #000000;
}


.ordem-servico-container {
  font-family: 'Roboto', sans-serif;
  max-width: 750px;
  margin: 20px auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  background: #ffffff;
  border-radius: 10px;
  color: #000000;
}

.ordem-servico-container-principal{
  width: 100%;
}
/* Título Principal */
.header-title {
  font-size: 1.6rem;
  color: #000000;
  text-align: center;
  margin-bottom: 1.2rem;
  font-weight: 600;
  border-bottom: 2px solid #2563eb;
  padding-bottom: 0.5rem;
}

/* Seções */
.section {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
}



/* Título das Seções */
.section .info-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 1rem;
  color: #000000;
}

.section .info-header h2 {
  font-size: 1.4rem;
  font-weight: 500;
}

/* Ícones */
.icon {
  font-size: 1.3rem;
  color: #000000;
}

.principal-details{
  font-size: 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #000000;
}
/* Informações Principais */
.info-content p {
  font-size: 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #000000;
}

/* Seções específicas */
.fotos .foto-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.fotos .foto {
  flex: 1 1 45%;
  max-width: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
  text-align: center;
  background: #e2e8f0;
}

.fotos .foto img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #3b82f6;
}

.fotos .foto p {
  margin: 8px 0;
  color: #000000;
  font-weight: 500;
}

/* Assinatura */
.assinatura-imagem {
  text-align: center;
  margin-top: 8px;
  padding: 12px;
  background: #e2e8f0;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.assinatura-imagem img {
  max-width: 100%;
  height: auto;
}

.assinatura-texto {
  margin-top: 8px;
  font-weight: 500;
  color: #000000;
  font-weight: bold;
}

/* Ajustes adicionais */
.ordem-servico-container p {
  line-height: 1.4;
  color: #000000;
}


.principal-section {
  background-color: #f5f7fa; /* Cor de fundo suave */
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Revisão Section */
.revisao-section {
  background-color: #f5f7fa; /* Cor de fundo suave */
  border-radius: 8px;
  padding: 1.5rem;
  margin: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.revisao-header {
  display: flex;
  align-items: center;
  color: #000000;
  border-bottom: 2px solid #000;
  padding-bottom: 5px;

}

.revisao-header h2 {
  font-weight: 600;
  margin-left: 0.5rem;
}



.icon {
  color: #3498db;
  font-size: 1.2rem;
}


.revisao-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #000000;
}

.revisao-header h2 {
  font-size: 18px;
  margin-left: 0.5rem;
  margin-top: 3px;
}

.revisao-details p {
  margin: 0.3rem 0;
  font-size: 12px;
  color: #000000;
}

.icon {
  color: #000000;
  font-size: 1.2rem;
}

.foto {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.foto img{
  width: 180px;
  height: 180px;
  margin-bottom: 5px;
}


.foto .visualizar-img-foto{
  margin-top: 50px;
  margin-bottom: 50px;
}

.visualizar-img-foto {
  text-decoration: none;
}


.foto a {
  text-decoration: none;
}

.icone-logico svg {
  margin-right: 8px; 

}

.icone-logico{
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

.logico-icone {
  transform: translateY(1px); /* Desce o ícone 2 pixels para baixo */
}

.icone-logico-container {
  display: flex;
  gap: 10px; /* Espaçamento entre os itens */
}

@media print {

  
.ordem-servico-container {
  border-radius: 0px;

}

  .visualizar-img-foto {
      display: none;
  }

  .foto {
    margin-bottom: 0px;
  }
  
  .foto img{

    margin-bottom: 0px;
  }


}