

.Bt-button-modal {
    display: flex;
    background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
    border: 1px solid rgb(192, 192, 192);
    height: 52px;
    border-radius: 8px;
    padding: 7px;
    margin-top: 10px;
    }
    
    .Bt-button-modal button {
    font-size: 15px;
    background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
    margin-left: 17px;
    width: 100px;
    border: 1px solid rgb(102, 102, 102);
    cursor: pointer;
    border-radius: 5px;
    }
    
    .Bt-button-modal button:hover {
    border: 1px solid #aaa7a7;
    
    }

    .container-cad-prodMobi1 {
        display: grid;
        width: 100%;
        grid-template-columns: 2fr 2fr 2fr 2fr;
        grid-template-areas: "content1 content2 content3 content4";
        grid-gap: 0.5rem;
        margin-bottom: 7px;
    }

    
    .container-cad-prodMobi2 {
        display: grid;
        width: 100%;
        grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr;
        grid-template-areas: "content1 content2 content3 content4 content5 content6";
        grid-gap: 0.5rem;
        margin-bottom: 7px;
    }

    .container-cad-prodMobi8 {
        display: grid;
        width: 100%;
        grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
        grid-template-areas: "content1 content2 content3 content4 content5";
        grid-gap: 0.5rem;
        margin-bottom: 7px;
    }

    .container-cad-prodMobi3 {
        display: grid;
        width: 100%;
        grid-template-columns: 2fr 2fr 2fr;
        grid-template-areas: "content1 content2 content3";
        grid-gap: 0.5rem;
        margin-bottom: 7px;
    }


    @media only screen and (max-width: 1020px) {

        .container-cad-prodMobi1{
            grid-template-columns: 1fr;
            grid-template-areas:
              "content1"
              "content2"
              "content3"
              "content4";
              grid-gap: 0rem;
              margin-bottom: 5px;
          }

        .container-cad-prodMobi2{
            grid-template-columns: 1fr;
            grid-template-areas:
              "content1"
              "content2"
              "content3"
              "content4"
              "content5"
              "content6";
              grid-gap: 0rem;
              margin-bottom: 5px;
          }

        .container-cad-prodMobi3{
            grid-template-columns: 1fr;
            grid-template-areas:
              "content1"
              "content2"
              "content3";
              grid-gap: 0rem;
              margin-bottom: 5px;
          }
          .container-cad-prodMobi8{
            grid-template-columns: 1fr;
            grid-template-areas:
              "content1"
              "content2"
              "content3"
              "content4"
              "content5";
              grid-gap: 0rem;
              margin-bottom: 5px;
          }
        }