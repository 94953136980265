.importmodelfields{
    background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
    border: 1px solid rgb(192, 192, 192);
    margin-bottom: 1px;
    padding: 10px;
    display: flex;
 
}

.importmodelfields-titulo{
 font-size: 14px;
 font-weight: normal;
}
.icon-modelos{
    margin-right: 5px;
}

.importmodelfields-div{
max-height: 350px;
overflow-y: auto;
padding-right: 2px;
}
