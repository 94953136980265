.button_itens {
    height: 30px;
    width: 100%;
    padding: 0px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    background-color: #111827;
    margin-top: 23px;
}

@media only screen and (max-width: 768px) {
    .button_itens {
        margin-bottom: 10px;
        margin-top: 0px;
    }
}