
.detalhamentoTicket h1{
    font-size: 14px;
}

.cont-ticket{
    padding: 20px;
}

.containerStep{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Tcontainer{
    display: flex;
    flex-direction: column;
    align-Items: center; 
    padding: 5px;  
}

.Tcontainer{
  display: flex;
  flex-direction: column;
  align-Items: center; 
  padding: 5px;  
}

.Tcontainer2{
  display: flex;
  flex-direction: column;
  align-Items: center; 
  padding: 5px;
  pointer-events:none;
}

.Tcontainer2{
  display: flex;
  flex-direction: column;
  align-Items: center; 
  padding: 5px;
  pointer-events:none;
}

.selecao{

    text-align: center;
    font-size: 13px; 
    background-color: #fff;
  }
  
  .solicitacao{
    font-size: 13px; 
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  
  .input{
    margin: 5px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .estrela-button{
    background-color: #fff;
  }

  .selecao-estrela{

    text-align: center;
    font-size: 13px; 
    background-color: #fff;
  }


  .textarea-estrela {
    margin-top: 10px;
    background-color: #fff;
    -moz-box-shadow: inset 0 1px 2px 0 rgba(24, 50, 71, 0.05);
    -webkit-box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    box-shadow: inset 0 1px 2px 0 rgb(24 50 71 / 5%);
    color: #12344D;
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
    display: inline-block;
    padding: 6px 6px;
    border-radius: 4px;
    border: 1px solid #92989e;
    -moz-transition: border linear 0.2s;
    -o-transition: border linear 0.2s;
    -webkit-transition: border linear 0.2s;
    transition: border linear 0.2s;
    width: 50%;
    border-width: 1px;
    height: 70px;
    margin-bottom: 4px;
  }

.button-estrela{
  width: 30%;
  height: 46px;
  background:#13293D;
  border-radius: 10px;
  margin-bottom: 20px;
  display:flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 22px;
  margin-top: 10px;
  color: #fff;
}

.button-estrela:hover{
  opacity: 0.8;
}