
  .characters {
    list-style: none;
    padding-left: 0;
  }
  
  .characters li {
    background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
    border: 1px solid rgb(201, 201, 201);
    border-radius: 5px;
    margin: 8px;
    padding: 6px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .characters p {
    max-width: none;
    font-weight: bold;
    margin-top: 2px;
  }
  

.icone-resumo{
  margin-top: 2px;
  cursor: pointer;
  margin-left: 10px;

}



.icone-resumo-iconCaixa{
  cursor: pointer;
  margin-right: 5px;
  color: #38c70d;
}

.icone-resumo-iconQrcode{
  cursor: pointer;
  margin-right: 5px;
  color: rgb(0, 0, 0);
}

.icone-resumo-IoStar{
  cursor: pointer;
  margin-right: 5px;
  color: #dfdf29;
}

.icone-resumo-image{
  cursor: pointer;
  margin-right: 5px;
}

.icone-resumo:hover{
  color: #ff0000;
}
.div-info-alert{
  margin-bottom: 20px;
}

.btn-flex{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
}

.btn-alert-flex{
  background-color: rgb(34, 45, 50);
  padding: 10px;
  border-radius: 5px;
  width: 50px;
  cursor: pointer;
  align-items: center;
}

.btn-alert-flex-nao{
  background-color: rgb(221, 51, 51);
  padding: 10px;
  border-radius: 5px;
  width: 50px;
  cursor: pointer;
  align-items: center;
}


.btn-alert-flex:hover{
  opacity: 0.8;
}

.btn-alert-flex-nao:hover{
  opacity: 0.8;
}

.container-beautiful{
  width: 100%;
  height: 100%;
  padding: 10px;
}

.container-beautiful2{
  width: 100%;
  height: 420px;
  background-color: #fff;
  overflow-y:auto; 
  border-radius: 10px;
}

.container-beautiful1{
  width: 100%;
  height: 430px;
  background-color: #fff;
  padding-right: 5px;
  border-radius: 10px;
  box-shadow: 0px 5px 5px rgba(50, 50, 50, 0.514);
}

.list-Beautiful{
  border: 6px solid  #000000;
  width: 320px;
  height: 500px;
  background-color: #F0F0F0;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;

}


.list-Beautiful2{
  border: 2px solid  #12344D;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  height: 480px;
  overflow-y:auto;  
  
}


.list-BeautifulBar{
  width: 100%;
  height: 40px;
  background-color: #12344D;
 padding-top: 6px;
 padding-left: 10px;
 display: flex;
 border-top-left-radius: 10px;
 border-top-right-radius: 10px;
} 

.list-BeautifulBarLab{
  margin-left: 15px;
  font-size: 18px;
  color: #fff;
  padding-top: 4px;
}

.list-BeautifulBarIcon{
  font-size: 25px;
  color: #fff;
}

.title-list-Beautiful{
height: 25px;
background-color: #12344D;
width: 100%;
border-radius: 5px;
margin-bottom: 5px;
}

.texto-list-Beautiful{
font-size: 14px;
font-weight: bold;
color: #fff;
margin-left: 10px;
margin-top: 4px;
}

.icone-resumo-label{
  display: flex;
  margin-top: 2px;
}

.label-resumo{
  font-weight: bold;
  color: #000
}

.label-resumo2{
  font-weight: bold;
  color: rgb(189, 187, 187)
}


.icone-resumo-Beautiful{
  font-size: 10px;
}

.icon-Beautiful{
  display: flex;
}

.title-modal-atv{
  display: flex;
  font-size: 18px;
}


.container-modal-incluir-atv2{
  width: 100%;
  max-height: 490px;
  overflow-y: auto;
  padding: 20px;
}



.phone-frame {
  width: 360px;
  height: 640px;
  background-color: #000;
  border-radius: 30px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: relative;
}

.screen {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 20px;
  overflow: hidden;
}

.header {
  width: 100%;
  height: 50px;
  background-color: #4caf50;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.content {
  padding: 20px;
  font-size: 16px;
}

.home-button {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.icone-resumo-div{
  width: 100%;
  display: flex;
  justify-content: space-between; /* Isso alinha as divs esquerda e direita */
}

.icon-Beautiful-btn{
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  height: 100%; /* Garante que a altura seja 100% da div */
}


.icone-resumo-icon{
  cursor: pointer;
  margin-right: 5px;
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  height: 100%; /* Garante que a altura seja 100% da div */
}
