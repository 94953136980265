.cad-prod{
    background-color: #fff;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}



.container-cad-prod3 {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr;
    grid-template-areas: "content1";
    grid-gap: 0.5rem;
   
}

#content1 {
    grid-area: content1;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
}

#content2 {
    grid-area: content2;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
}

#content3 {
    grid-area: content3;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    color: #455560;
}

#content4 {
  grid-area: content4;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

.cad-prod-titulo .cad-prod-titulo-h1{
    font-size: 18px;
    margin-bottom: 10px;
}

.cont-card-title-cad {
    height: 35px;
    background: #f1f0f0;
    padding-left: 10px;
    border: 2px solid #12344D;

    display: flex;
    margin-bottom: 5px;
    justify-content: space-between;
}


.cont-card-title-cad .div-cont-card-title{
    display: flex;
    }

    .cont-card-title-cad  .icon-car-title{
        font-size: 22px;
        margin-top: 6px;
        margin-right: 8px;
      }
      
      .cont-card-title-cad h1 {
        font-size: 18px;
        padding-top: 8px;
        color: #000000;
      
    }


   
      .title-text {
        display: flex;
        width: 100%;
      }

      
      
      .size-x {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background-color: #455560;
        margin-right: 10px;
      }
      
      .circle {
        background: #455560;
        color: #fff;
        border-radius: 50%;
        font-size: 18px;
        display: flex;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
      }

      

.Bt-button-cad {
    display: flex;
    background-image: linear-gradient(to bottom, #fafafa, rgb(231, 229, 229));
    border: 1px solid rgb(192, 192, 192);
    height: 52px;
    border-radius: 8px;
    padding: 7px;
    margin-top: 10px;
    }
    
    .Bt-button-cad button {
    font-size: 15px;
    background-image: linear-gradient(to bottom, #fafafa, #e6e6e6);
    margin-left: 17px;
    width: 100px;
    border: 1px solid rgb(102, 102, 102);
    cursor: pointer;
    border-radius: 5px;
    }
    
    .Bt-button-cad button:hover {
    border: 1px solid #aaa7a7;
    
    }
    


    .lab-ativo {
      margin-left: 5px;
      margin-top: 3px;
      display: block;
      font-weight: 500;
      color: #12344D;
    }

    .container-modal{
      padding: 10px;
    }

    .modal-titulo{
      font-size: 16px;
      margin-top: 2px;
      margin-left: 10px;
      color: #fff;
    }
   .titulo-card-visualizacao{  
      text-align: center;
          background-color: #13293D;
          box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
          padding-left: 6px;
          padding-right: 6px;
          padding-top: 5px;
          padding-bottom: 5px;
          border-radius: 6px;
          color: #ffffff;
          font-size: 18px;
          margin-left: 20%;
          margin-right: 20%;
          margin-bottom: 10px;
          font-size: 16px;
  }
  
  
    @media only screen and (max-width: 768px) {
   
          .cont-center-btn{
            height: 10px;
          }
        
         
    }