.btn-add-cal{
    background: #12344D;
    color: #fff;
    width: 223px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .btn-add-cal:hover{
    opacity: 0.8;
  }